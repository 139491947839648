import request from "tools/request.js"

// 获取订单列表
class dingdan_get {
	constructor(parent) {
	    this.parent = parent
	}
	
	m_main() {
		const that = this;
		
		let page 	  		 = that.parent.page;
		let page_size		 = that.parent.page_size;
		
		
		let ding_id = that.parent.ding_id; 
		 let huiyuan_id = that.parent.huiyuan_id; 
		 
		let session = localStorage.getItem("session");
		
		let params = {
			page : page,
			size : page_size, 
			ding_id : ding_id, 
			huiyuan_id : huiyuan_id,
			session : session
		} 
		
		request.post('/dingdan/dingdan_get',params).then(res => { 
			that.parent.m_dingdan_list = res.data.result; 
			that.parent.max_page = res.data.count;
		})
		  
	}
}

export default dingdan_get
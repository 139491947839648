import request from "tools/request.js"

//一元抽设置 获取
class yiyuanchou_set_get {
	constructor(parent) {
	    this.parent = parent
	}
	
	m_main() {
		const that = this;
	 
		 let m_ding_id 		 	 = that.parent.m_ding_id;
		  let session = localStorage.getItem("session");
		  
		 let params = {
		 	m_ding_id : m_ding_id,
			session : session
		 } 
		 
		request.post('/dingdan/dingdan_info_get',params).then(res => { 
			  
		  
		 
			 if(res.data.state == 200){
			 	that.parent.m_dingdan_info = res.data.m_dingdan_info; 
			 	that.parent.m_shijian_info = res.data.m_shijian_info; 
			 }else{
			 	that.parent.m_dingdan_info = [];
			 }
			  
		})
		 
	}
}

export default yiyuanchou_set_get
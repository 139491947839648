<template>
	<div class="yu_box_1">
		<div>
			<br />
			<el-row>
				<el-col class="yu_input_label" :span="8">
					订单id:
				</el-col>
				<el-col :span="16">
					<el-input v-model="m_dingdan_info.ding_id"></el-input>
				</el-col>
			</el-row>
			<br />

			<el-row>
				<el-col class="yu_input_label" :span="8">
					用户id:
				</el-col>
				<el-col :span="16">
					<el-input v-model="m_dingdan_info.ding_user_id"></el-input>
				</el-col>
			</el-row>
			<br />

		  
			<el-row>
				<el-col class="yu_input_label" :span="8">
					场地:
				</el-col>
				<el-col :span="16">
					<el-input v-model="m_dingdan_info.chdi_name"></el-input>
				</el-col>
			</el-row>
			<br />
			
			<el-row>
				<el-col class="yu_input_label" :span="8">
					订单总金额  :
				</el-col>
				<el-col :span="16">
					<el-input v-model="m_dingdan_info.ding_jine"></el-input>
				</el-col>
			</el-row>
			<br />
			
			
			<el-row>
				<el-col class="yu_input_label" :span="8">
					下单时间  :
				</el-col>
				<el-col :span="16">
					<el-input v-model="m_dingdan_info.ding_time"></el-input>
				</el-col>
			</el-row>
			<br />
			
			<el-row>
				<el-col class="yu_input_label" :span="8">
					支付状态  :
				</el-col>
				<el-col :span="16">
					<el-input v-model="m_dingdan_info.ding_state"></el-input>
				</el-col>
			</el-row>
			<br />
			
			
			<el-row>
				<el-col class="yu_input_label" :span="8">
					支付方式  :
				</el-col>
				<el-col :span="16">
					<el-input v-model="m_dingdan_info.ding_type"></el-input>
				</el-col>
			</el-row>
			<br />
			
			<el-row>
				<el-col class="yu_input_label" :span="8">
					验证码  :
				</el-col>
				<el-col :span="16">
					<el-input v-model="m_dingdan_info.ding_yanzhengma"></el-input>
				</el-col>
			</el-row>
			<br />
			
			<block v-for="(item,index) in m_shijian_info"> 
			 <el-row  >
			 	<el-col class="yu_input_label" :span="8">
			 		场次{{index + 1}}  :
			 	</el-col>
			 	<el-col :span="16">
			 		<el-input v-model="item.time"></el-input>
			 	</el-col> 
			 </el-row> 
			 <br />
			 </block>
		</div>
	</div>
</template>

<script>
	import dingdan_get from "./expand/dingdan_get.js";
	// import yiyuanchou_set_post from "./expand/yiyuanchou_set_post.js";

	export default {

		props: ["m_ding_id","parent"],
		data() {
			return {
				m_ding_id : "",
				m_dingdan_info: {}, 
				m_shijian_info: {}, 
			}
		},
		created() {
			const that = this;
			console.log("youyou");
			console.log(that.m_ding_id);
			that.dingdan_get = new dingdan_get(that);
			that.dingdan_get.m_main();
		},
		methods: {
			// manghe_set() {
			// 	const that = this;
			// 	that.manghe_set_post = new manghe_set_post(that);
			// 	that.manghe_set_post.m_main();
			// }
		}
	}
</script>


<style>
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>
